import { useState, useEffect } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import fileTransferService from '../services/filetransfer.service'
import Brand from '../components/Brand/Brand'
import Back from '../components/Back/Back'


const FileReceiver = () => {
    const [archivosPendientes, setArchivosPendientes] = useState([])
    const [error, setError] = useState(null)
    const [formData, setFormData] = useState({
        nombreUsuarioReceptor: '',
        nombreUsuarioRemitente: ''
    })

    const handleInputChange = e => {
        const { value, name } = e.target
        setFormData({ ...formData, [name]: value })
    }

    useEffect(() => {
        const obtenerArchivos = () => {
            if (formData.nombreUsuarioReceptor && formData.nombreUsuarioRemitente) {
                fileTransferService
                                    .obtenerArchivosPendientes(
                                            formData.nombreUsuarioReceptor, 
                                            formData.nombreUsuarioRemitente)
                                    .then(response => {
                                        setArchivosPendientes(response.data.archivos)
                                        setError(null)
                                    })
                                    .catch(error => {
                                        console.error('Error al obtener archivos pendientes:', error)
                                        setArchivosPendientes([])
                                        setError('No hay archivos pendientes')
                                    })
            } else {
                setArchivosPendientes([])
                setError('Por favor ingresa los nicks cuando te haya confirmado el envío')
            }
        }

        obtenerArchivos()
    }, [formData.nombreUsuarioReceptor, formData.nombreUsuarioRemitente])

    return (
        <main className='receiver'>
            <Back />
            <Brand />
            <hr />
            <Container>
                <Row className='justify-content-center align-items-center'>
                    <Col md={6}>
                        <Card>
                            <h2>Recibir Transferencia</h2>
                            <p>
                                Ingresa tu nick de destino y el nick del emisor para recibir el archivo
                            </p>
                            <form>
                                <label>Nick Destino:</label>
                                <input 
                                    type='text' 
                                    name='nombreUsuarioReceptor' 
                                    value={formData.nombreUsuarioReceptor} 
                                    onChange={handleInputChange} />
                                <label>Nick Emisor:</label>
                                <input 
                                    type='text' 
                                    name='nombreUsuarioRemitente'
                                    value={formData.nombreUsuarioRemitente} 
                                    onChange={handleInputChange} />
                            </form>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {error && <p>{error}</p>}
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {archivosPendientes.map(archivo => (
                    <div key={archivo.token} 
                         style={{ 
                            margin: '10px', 
                            border: '1px solid #ccc', 
                            padding: '10px', 
                            minWidth: '200px' }}>
                        <h3>{archivo.originalname}</h3>
                        {archivo.originalname.match(/\.(jpg|jpeg|png|gif)$/) && (
                            <img
                                src={`data:image/*;base64,${archivo.thumbnail}`}
                                alt={archivo.originalname}
                                style={{ maxWidth: '100px', maxHeight: '100px' }} />
                        )}
                        <button
                            onClick={() => {
                                fileTransferService
                                                    .descargarArchivo(archivo.token)
                                                    .then(response => {
                                                        const url = window.URL.createObjectURL(new Blob([response.data]))
                                                        const link = document.createElement('a')
                                                        link.href = url
                                                        link.setAttribute('download', archivo.originalname)
                                                        document.body.appendChild(link)
                                                        link.click()
                                                    })
                                                    .catch(error => console.error('Error al descargar archivo:', error))
                            }}>
                            Descargar
                        </button>
                    </div>
                ))}
            </div>
        </main>
    )
}

export default FileReceiver