import { Link } from 'react-router-dom'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlaneDeparture, faPlaneArrival } from '@fortawesome/free-solid-svg-icons'
import './Hero.css'


const Hero = () => {
    return (
        <>
            <p>
                Tu aplicación para hacer transferencias de archivos de forma segura y privada
            </p>
            <p>
                Por favor, selecciona si quieres enviar o recibir un archivo
            </p>
            <hr />
            <Container>
                <Row className='justify-content-center align-items-center my-5'>
                    <Col xs={6}>
                        <Card>
                            <Link to={'/send'}>
                                <FontAwesomeIcon icon={faPlaneDeparture} /> 
                                <p>Enviar Archivo</p>
                            </Link>
                        </Card>
                    </Col>
                    <Col xs={6}>
                        <Card>
                            <Link to={'/receive'}>
                                <FontAwesomeIcon icon={faPlaneArrival} /> 
                                <p>Recibir Archivo</p>
                            </Link>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <hr />
            <section>
                <p>
                    Created by <a href='https://thebulliam.com'>The Bulliam</a> and powered with 
                    React, Boostrap, Netlify, ExpressJS, NodeJS and Render.
                </p>
                <hr />
                <p>&copy;2024 Fylend - Todos los derechos reservados</p>
            </section>
        </>
    )
}

export default Hero