import { Card, Col, Container, Row } from 'react-bootstrap'
import { useState } from 'react'
import fileTransferService from '../services/filetransfer.service'
import Brand from '../components/Brand/Brand'
import Back from '../components/Back/Back'


const FileSender = () => {
    const [archivos, setArchivos] = useState([])
    const [progresos, setProgresos] = useState({})
    const [mostrarConfirmacion, setMostrarConfirmacion] = useState(false)
    const [token, setToken] = useState('')
    const [formData, setFormData] = useState({
        nombreEmisor: '',
        nombreDestinatario: '',
        archivosChange: []
    })

    const handleInputChange = e => {
        const { value, name, files } = e.target
        if (name === 'archivosChange') {
            const selectedFiles = Array.from(files).slice(0, 5) // Limita a 5 archivos
            setArchivos(selectedFiles)
            const initialProgresos = selectedFiles.reduce((acc, file) => {
                acc[file.name] = 0
                return acc
            }, {})
            setProgresos(initialProgresos)
            setFormData({ ...formData, archivosChange: selectedFiles })
        } else {
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSubmit = e => {
        e.preventDefault()
        const usernamePattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?]).{3,}$/
        if (!usernamePattern.test(formData.nombreEmisor) || !usernamePattern.test(formData.nombreDestinatario)) {
            alert('Los nombres de usuario deben contener letras, números y símbolos.')
            return
        }
        if (archivos.length === 0) {
            alert('Por favor selecciona al menos un archivo.')
            return
        }
        const formDataToSend = new FormData()
        formDataToSend.append('nombreEmisor', formData.nombreEmisor)
        formDataToSend.append('nombreDestinatario', formData.nombreDestinatario)
        archivos.forEach(file => {
            formDataToSend.append('archivos', file)
        })
        fileTransferService
                            .enviarArchivos(formDataToSend, event => {
                                if (event.lengthComputable) {
                                    const percent = Math.round((event.loaded / event.total) * 100)
                                    const updatedProgresos = { ...progresos }
                                    const fileName = event.target.responseURL.split('/').pop() // Extraer el nombre del archivo de la URL de respuesta
                                    updatedProgresos[fileName] = percent
                                    setProgresos(updatedProgresos)
                                }
                            })
                            .then(response => {
                                setToken(response.data.token)
                                setMostrarConfirmacion(true)
                                setArchivos([])
                                setFormData({
                                    nombreEmisor: '',
                                    nombreDestinatario: '',
                                    archivosChange: []
                                })
                            })
                            .catch(error => {
                                console.log('Error al enviar archivos:', error)
                            })
    }

    return (
        <main className='sender'>
            <Back />
            <Brand />
            <hr />
            <Container>
                <Row className='justify-content-center align-items-center'>
                    <Col md={6}>
                        <Card>
                            <h2>Enviar Transferencia</h2>
                            <form onSubmit={handleSubmit}>
                                <label>Nick Emisor:</label>
                                <input 
                                    type='text' 
                                    name='nombreEmisor' 
                                    value={formData.nombreEmisor} 
                                    onChange={handleInputChange} />
                                <label>Nick Destino:</label>
                                <input 
                                    type='text' 
                                    name='nombreDestinatario' 
                                    value={formData.nombreDestinatario} 
                                    onChange={handleInputChange} />
                                <label>Selecciona los Archivos:</label>
                                <input 
                                    type='file' 
                                    name='archivosChange' 
                                    multiple 
                                    onChange={handleInputChange} />
                                <button type='submit'>Enviar Archivos</button>
                            </form>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {archivos.length > 0 && (
                <div>
                    <h3>Progreso de Envío:</h3>
                    {archivos.map(file => (
                        <div key={file.name}>
                            <p>{file.name}</p>
                            <div style={{ border: '1px solid #ccc', width: '100%', height: '20px' }}>
                                <div style={{ 
                                        backgroundColor: 'blue', 
                                        width: `${progresos[file.name] || 0}%`, 
                                        height: '100%' 
                                    }}> 
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {mostrarConfirmacion && (
                <div>
                    <h2>Transferencia completada</h2>
                    <p>
                        La transferencia de archivos se ha realizado con éxito.
                        Proporcione el siguiente enlace al destinatario:
                    </p>
                    <p>{`${token}`}</p>
                </div>
            )}
        </main>
    )
}

export default FileSender