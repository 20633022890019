import axios from 'axios'

class FileTransferService {
    constructor() {
        this.api = axios.create({ baseURL: `${process.env.REACT_APP_API_URL}/base` })

        this.api.interceptors.request.use((config) => {
            const storedToken = localStorage.getItem('authToken')
            if (storedToken) {
                config.headers.Authorization = `Bearer ${storedToken}`
            }
            return config
        })
    }

    enviarArchivos(data, onUploadProgress) {
        return this.api.post('/enviar-archivos', data, {
            onUploadProgress,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    }

    obtenerArchivosPendientes(nombreDestinatario, nombreEmisor) {
        return this.api.get('/archivo-pendiente', {
            params: { nombreDestinatario, nombreEmisor }
        })
    }

    descargarArchivo(token) {
        return this.api.get(`/recibir-archivo/${token}`, {
            responseType: 'blob',
        })
    }
}

const fileTransferService = new FileTransferService()
export default fileTransferService